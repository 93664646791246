import React from 'react';
import PropTypes from 'prop-types';
import Footer from './footer';
import Header from './header';

const Layout = ({ className, children }) => (
  <>
    <Header />
    {/* add pt-12 to main when adding header    */}
    <main
      id="main"
      className={`${className} bg-white dark:bg-cloud-700 md:pt-12 `}
    >
      {children}
    </main>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
