import React from 'react';
import Container from './container';

const TelegramIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
  </svg>
);

const TwitterIcon = () => (
  <svg
    fill="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    className="w-5 h-5"
    viewBox="0 0 24 24"
  >
    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
  </svg>
);

const LinkedInIcon = () => (
  <svg
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="0"
    className="w-5 h-5"
    viewBox="0 0 24 24"
  >
    <path
      stroke="none"
      d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
    />
    <circle cx="4" cy="4" r="2" stroke="none" />
  </svg>
);

const Footer = () => (
  <footer className=" text-gray-800 dark:text-offwhite bg-cloud-100 dark:bg-cloud-700">
    <Container className="flex flex-col items-center py-4 sm:flex-row">
      <a className="flex items-center justify-center font-medium title-font md:justify-start " />
      <p className="mt-4 text-sm sm:pl-4 sm:py-2 sm:mt-0">
        © 2021 Akshay
      </p>
      <span className="inline-flex justify-center mt-4 sm:ml-auto sm:mt-0 sm:justify-start">
        <a
          className=""
          href="https://t.me/awsmakshay"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TelegramIcon />
        </a>
        <a
          className="ml-3 "
          href="https://twitter.com/awsm_akshay"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon />
        </a>

        {/* <a className="ml-3 ">
						<LinkedInIcon />
					</a> */}
      </span>
    </Container>
  </footer>
);

export default Footer;
