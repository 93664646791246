import { Link } from 'gatsby';
import React from 'react';
import Container from './container';
import ThemeToggle from './themeToggle';

const Header = () => (
  <header className="top-0 z-50 w-full text-center text-gray-900 bg-white border-b-2 bg-opacity-95 md:fixed dark:text-white dark:bg-cloud-500 border-opacity-10 border-cloud-900 body-font">
    <Container>
      <div className="flex flex-col flex-wrap items-center py-3 mx-auto md:flex-row">
        <Link
          to="/"
          className="flex items-center mb-4 font-medium title-font md:mb-0"
        >
          <div className="pr-1 text-3xl font-bold tracking-tighter cursor-pointer text-gradient">
            Akshay
          </div>
        </Link>
        <nav className="flex flex-wrap items-center justify-center text-lg md:ml-auto ">
          <Link
            to="/uses"
            className="mr-5 dark:text-white underline-gradient "
          >
            Uses
          </Link>
          <Link
            to="/resources"
            className="mr-5 dark:text-white underline-gradient "
          >
            Resources
          </Link>
          <ThemeToggle />
          {/* <Link
              to="/blog"
              className="mr-5 text-sm font-semibold text-gray-300 hover:text-gray-400"
            >
              Blog
            </Link> */}
        </nav>
      </div>
    </Container>
  </header>
);

export default Header;
